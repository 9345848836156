#widget-container {
    border-radius: 1rem;
}

#widget-container .widget-card-content .content-value {
    text-align: center;
}

#widget-container .widget-card-action {
    justify-content: flex-end;
}

/* ParkingHeader */
#header-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
}
#header-container .title-content {
    display: flex;
    flex-direction: column;
}
#header-container .parking-filter-inputs {
    padding: 0;
    margin-top: 1rem;
}
#header-container .parking-filter-buttons {
    display: flex;
    justify-content: flex-end;
}
#header-container .filter-itens {
    margin: 0.5rem;
}
#header-container .select {
    margin: 0.5rem 0 0;
    padding: 0.5rem 0 0;
}

@media (max-width: 700px) {
    #header-container .parking-filter-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .parking-filter-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .filter-itens{
        width: 100%;
        margin-top: 0.5rem;
    }
}

.hour-div {
    margin: 0.75rem 0;
    align-items: center;
}

.content-form .form .half-input{
    width: 49%;
    margin-bottom: 10px;
}

.content-form .form {
    margin-top: 1.5rem;
    padding: 0.5rem;
    width: 100%;
}
.content-form .form .form-div-title {
    margin: 0.5rem 0;
}
.content-form .form .full-input {
    width: 100%;
    margin-bottom: 10px;
}
.content-form .form .half-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.content-form .form .half-input{
    width: 49%;
    margin-bottom: 10px;
    padding: 0.5rem;
}
.content-form .form .add-data{
    display: flex;
    justify-content: flex-end;
}
.content-form .form .button-content{
    display: flex;
    justify-content: flex-end;
}