.img-container {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}
.img-container img{
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.form-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}