.dialog-title {
    text-align: center;
}
.content-newcash {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-newcash .input-content {
    margin-top: 1.5rem;
}
.content-newcash .input-content .filter-itens {
    margin: 0 0.5rem;
}
.full-input {
    width: 100%;
}


/* CashHeader */
#header-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
}
#header-container .title-content {
    display: flex;
    flex-direction: column;
}
#header-container .cash-filter-content {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1rem;
}

@media (max-width: 700px) { /* 900 */
    #header-container .cash-filter-content {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .cash-filter-content .filter-itens{
        width: 100%;
        margin-top: 0.5rem;
    }
}


@media (max-width: 750px) {
    #header-container .holder-filter-content {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .holder-filter-content .filter-itens{
        width: 100%;
        margin-top: 0.5rem;
    }
}