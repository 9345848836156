/* MozartDrawer */
#drawer-my-data .avatar {
    color: rgb(255, 255, 255);
    background-color: rgb(231, 20, 28);
}


/* MainWithoutData */
#without-data {
    height: 50vh;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem;
}
#without-data .no-result-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}


/* MainWithData */
#content-with-data {
    height: 100%;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem;
}
#content-with-data .result-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
}


/* Loader */
#loader img {
    margin-bottom: 2rem;
    animation: loading 2s infinite alternate;
}

@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}