#widget-container {
    border-radius: 1rem;
}

#widget-container .widget-card-content .content-value {
    text-align: center;
}

#widget-container .widget-card-action {
    justify-content: flex-end;
}

/* ParkingHeader */
#header-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
}
#header-container .title-content {
    display: flex;
    flex-direction: column;
}
#header-container .parking-filter-inputs {
    padding: 0;
    margin-top: 1rem;
}
#header-container .parking-filter-buttons {
    display: flex;
    justify-content: flex-end;
}
#header-container .filter-itens {
    margin: 0.5rem;
}
#header-container .select {
    margin: 0.5rem 0 0;
    padding: 0.5rem 0 0;
}

@media (max-width: 700px) {
    #header-container .parking-filter-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .parking-filter-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .filter-itens{
        width: 100%;
        margin-top: 0.5rem;
    }
}