/* MyAccountTab */
#my-account-tab-container{
    margin: 1rem;
    padding: 0;
}
#my-account-tab-container #mydata-options {
    border-radius: 1.5rem 1.5rem 0 0;
}

#my-account-tab-container .mydata-tab-panel {
    border-radius: 0 0 1.5rem 1.5rem;
}

/* Forms */
.form-content {
    display: flex;
    flex-direction: column;
}
.form-content .textField {
    margin: 0.5rem 0;
}
.form-content .textfield-icon {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.5rem;
}
.form-content .textfield-icon .textField-button {
    margin: 0.5rem 0 0.2rem;
    width: 83%;
}
.form-content .textfield-icon .button-mydata {
    margin: 0;
    width: 15%;
    height: 4rem;
    border-radius: 5px;
}

/* ChangePasswordTabPanel */
.change-password-username {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.change-password-username  p {
    margin: 0 1rem 2rem;
    text-align: center;
    font-size: 1rem;
}
.change-password-username .change-password-field {
    width: 40%;
}
.change-password-username .form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.change-password-username .form-content .change-password-field  {
    width: 100%;
    margin-bottom: 1rem;
}
.change-password-username .form-content .warning-change-password {
    color: red;
}