/* SearchHolder */
.dialog-title {
    text-align: center;
}
.content-search-holder {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content-search-holder .filter-itens {
    margin: 1.5rem 0 0.5rem;
}


/* FormNewHolder */
.content-form-newholder {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.content-form-newholder .form {
    margin-top: 1.5rem;
    padding: 0.5rem;
    width: 100%;
}
.content-form-newholder .form .form-div-title {
    margin: 0.5rem 0;
}
.content-form-newholder .form .full-input {
    width: 100%;
    margin-bottom: 10px;
}
.content-form-newholder .form .half-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-form-newholder .form .half-input{
    width: 49%;
    margin-bottom: 10px;
}
.content-form-newholder .form .add-data{
    display: flex;
    justify-content: flex-end;
}
.content-form-newholder .form .button-content{
    display: flex;
    justify-content: flex-end;
}


@media (max-width: 500px) {
    .content-form-newholder .form .half-div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .content-form-newholder .form .half-input{
        width: 100%;
        margin-bottom: 10px;
    }
}

/* HolderHeader */
#header-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
}
#header-container .title-content {
    display: flex;
    flex-direction: column;
}
#header-container .holder-filter-inputs {
    padding: 0;
    margin-top: 1rem;
}
#header-container .holder-filter-buttons {
    display: flex;
    justify-content: flex-end;
}
#header-container .filter-itens {
    margin: 0.5rem;
}
#header-container .select {
    margin: 0.5rem 0 0;
    padding: 0.5rem 0 0;
}

@media (max-width: 700px) {
    #header-container .holder-filter-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .holder-filter-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .filter-itens{
        width: 100%;
        margin-top: 0.5rem;
    }
}

/* PaymentsDialog */
#payment-dialog .content-payments{
    margin-top: 1.5rem;
}
#payment-dialog .content-payments .button{
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
}


/* PaymentsCard */
.payment-container {
    margin: 0.5rem;
    min-width: 210px;
    width: 250px;
}
.payment-container .late{
    background: rgba(231, 27, 27, 0.792);
    color: #FFF;
    padding: 1rem;
}
.payment-container .updated{
    background: rgba(13, 180, 13, 0.75);
    color: #FFF;
    padding: 1rem;
}
.payment-container .content{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* ConfirmPaymentDialog */
.content-confirm-payment {
    display: flex;
    align-items: center;
}