html, body { 
    width: 100vw; 
    height: 100vh; 
    margin: 0;
    padding: 0;
}

body {
    font: 400 1rem "Roboto", sans-serif;
}

button {
    cursor: pointer;
}

.button {
    font-size: 1rem;
    margin-top: 20px;
    height: 2.5rem;
    width: 100%;
    border-radius: 5px;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.button-blue {
    border: 1px solid #1283af;
    background: none;
    color: #189fd4;
}
.button-blue:hover {
    opacity: 1;
    background: #189fd4;
    color: #fff;
}
.button-green {
    border: 1px solid #12af1a;
    background: none;
    color: #12af1a;
}
.button-green:hover {
    opacity: 1;
    background: #21db2b;
    color: #fff;
}
.button-red {
    border: 1px solid #af2a12;
    background: none;
    color: #d4361a;
}
.button-red:hover {
    opacity: 1;
    background: #d4361a;
    color: #fff;
}
.button-gray {
    color: #333;
    border: 1px solid #b1b1b1;
    background: #dbdbdb;
}

.dialog-title-form {
    text-align: center;
}
.content-form {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.content-form .form {
    margin-top: 1.5rem;
    padding: 0.5rem;
    width: 100%;
}
.content-form .form .form-div-title {
    margin: 0.5rem 0;
}
.content-form .form .full-input {
    width: 100%;
    margin-bottom: 10px;
}
.content-form .form .half-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
}
.content-form .form .half-input{
    width: 49%;
    margin-bottom: 10px;
}
.content-form .form .add-data{
    display: flex;
    justify-content: flex-end;
}
.content-form .form .button-content{
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 500px) {
    .content-form .form .half-div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .content-form .form .half-input{
        width: 100%;
        margin-bottom: 10px;
    }
}

@media(max-width: 1080px) {
    html {
        font-size: 93.75%;
    }
}

@media(max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}

.content-form .form .half-input{
    width: 49%;
    margin-bottom: 5px;
}