#dashboard-container #grid-container {
    margin: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
#dashboard-container #history-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 1rem;
}
#dashboard-container #history-content .no-result-content {
    display: flex;
    height: 45vh;
    align-items: center;
    justify-content: center;
    text-align: center;
}